@import "../../../colors";

.ShoppingCart {
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  background-color: #fff;
  border-left: 1px solid $borderLight;
  transition: transform 0.2s;

  &.closed {
    transform: translateX(301px);
  }

  .cart-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .top-section {
      padding: 18px 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: rgba(0, 0, 0, 0.02);
      align-items: center;
      border-bottom: 1px solid $borderLight;

      i {
        font-size: 50px;
        line-height: 0;
        cursor: pointer;
        margin-right: 10px;
      }

      p {
        margin: 0;
        font-weight: 600;
        font-size: 17px;
      }
    }

    .cart-content {
      flex: 1;
      overflow-y: auto;

      .empty-cart-container {
        text-align: center;

        p {
          font-weight: 600;
          margin: 25px 0 20px;
        }

        button {
          width: 80%;
        }
      }

      .cart-products-container {

        .cart-product {
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          padding: 10px;
          display: flex;
          flex-direction: row;

          .product-image-container {
            width: 56px;
            margin-right: 10px;

            img {
              width: 100%;
              display: block;
              border: 1px solid $borderDark;
              border-radius: 4px;
            }
          }

          .product-center-container {
            width: 143px;

            p {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              color: #666666;
              margin: 0 0 5px;
              font-size: 14px;
            }

            .amount-container {
              border-radius: 4px;
              background-color: $primaryColorLight;
              display: inline-block;
              position: relative;
              line-height: 0;
  
              .amount-container-inner {
                display: flex;
  
                .quantity-button {
  
                  .quantity-button-inner {
                    font-size: 25px;
                    font-weight: 400;
                    height: 26px;
                    width: 28px;
                    margin: 4px;
                    text-align: center;
                    background-color: #fff;
                    color: #000;
                    border-radius: 4px;
                    padding: 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;
  
                    &:hover {
                      background-color: rgba(255, 255, 255, 0.7);
                    }
                  }
                }
  
                .quantity-amount {
                  color: $primaryColor;
                  font-weight: bold;
                  border: none;
                  width: 28px;
                  height: 34px;
                  text-align: center;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-around;
                  font-size: 15px;
                }
              }
            }
          }

          .product-right-container {
            width: 60px;
            margin-left: 10px;
            text-align: right;

            p {
              color: $successColor;
              margin: 0 0 5px;
              font-size: 14px;

              &.price-free {
                color: $successColor;
              }
            }

            a {
              text-align: center;
              width: 16px;
              height: 16px;
              display: inline-block;
              border-radius: 16px;
              color: #bbb;
              font-size: 20px;
              line-height: 12px;
              border: 1px solid #bbb;
              cursor: pointer;

              &:hover {
                color: $primaryColor;
                border-color: $primaryColor;
              }
            }
          }
        }
      }
    }

    .checkout-button-container {
      padding: 3px;
      border-top: 1px solid $borderLight;

      button {
        width: 100%;
      }
    }
  }
}
