@import "../../../colors";

.Orders {

  .ordersOuterContainer {
    max-width: 1280px;
    margin: auto;
    margin-top: 40px;

    h1 {
      font-size: 1.5rem;
      margin-top: 10px;
    }

    h2 {
      font-size: 1.2rem;
      margin: 0;
      margin-top: 20px;
      margin-bottom: 5px;
      padding: 3px 0;
    }

    .ordersContainer {

      .order {
        margin-bottom: 10px;
        overflow-x: auto;

        .topContainer {
          display: flex;
          justify-content: space-between;
          font-size: 13px;
          border-bottom: 1px dashed $borderDark;
          padding-bottom: 20px;

          .date,
          .status {
            font-weight: 600;
          }
        }

        .orderSummary {

          h2 {
            margin-top: 5px;
            font-size: 20px;
          }

          table {
            width: 100%;
            margin-bottom: 0;

            td {
              padding: 3px;

              &.active {
                color: $successColor;
              }

              &.inactive {
                color: $primaryColor;
              }

              &.subscriptionDate {
                min-width: 100px;
              }
            }

            td:first-of-type {
              max-width: 50%;
            }

            td:nth-of-type(2) {
              text-align: center;

              &.subscriptionStartDateHeader {
                text-align: left;
              }
            }

            td:last-of-type {
              text-align: right;
            }

            thead td {
              font-weight: 600;
              font-size: 0.9rem;
            }

            tbody {
              td {
                font-size: 0.9rem;
              }
            }
          }

          .totalsContainer {
            border-top: 1px dashed $borderDark;
            border-bottom: 1px dashed $borderDark;
            display: flex;
            flex-direction: row-reverse;
            padding-top: 2px;
            padding-bottom: 25px;

            .totalsInner {
              margin-top: 5px;

              .totalLine {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                p {
                  margin: 0;
                  font-size: 14px;

                  &:first-of-type {
                    margin-right: 60px;
                    font-weight: 600;
                  }

                  &:last-of-type {
                    margin-right: 3px;
                  }
                }

                &.rewardsLine {
                  color: $successColor;
                }

                &.grandTotal {
                  border-top: 1px dashed $borderDark;
                  margin-top: 5px;
                  padding-top: 5px;

                  p {
                    font-size: 14px;
                  }

                  p:nth-of-type(2) {
                    font-weight: bold;
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
