@import "../../colors";

.Home {
  position: relative;

  header {
    border-bottom: 1px solid $borderLight;

    .header-inner {
      max-width: 1280px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-left-container {
        display: flex;
        align-items: center;

        .brand-image {
          height: 40px;
          margin-right: 1rem;
          margin: 13px 15px 8px;
        }

        .header-link {
          font-size: 15px;
          color: #333;
          font-weight: 600;
          margin-left: 25px;
          text-decoration: none;
        }
      }

      .cart-button {
        position: relative;
        height: 44px;
        width: 44px;
        border-radius: 44px;
        background-color: transparent;
        padding: 0;
        margin: 0;
        cursor: pointer;
        vertical-align: middle;
        margin-left: 15px;
  
        img {
          width: 18px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-55%, -50%);
          opacity: 0.6;
          z-index: 1;
        }
  
        &:hover {
          background-color: rgba(0, 0, 0, 0.03);
        }
  
        .cart-amount {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 2;
  
          .cart-amount-inner {
            height: 18px;
            width: 18px;
            border-radius: 18px;
            background-color: $primaryColor;
            position: relative;
  
            .cart-amount-text {
              font-size: 12px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              letter-spacing: 0;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .purchase-modal-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999999;

    .purchase-modal-body-container {
      position: absolute;
      background-color: #fff;
      padding: 20px;
      padding-top: 30px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 3px;
      box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
      min-width: 500px;
      max-height: calc(100vh - 20px);
      overflow: auto;
  
      .close-button {
        height: 26px;
        width: 26px;
        border-radius: 26px;
        text-align: center;
        cursor: pointer;
        right: 5px;
        top: 5px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-around;
  
        i {
          color: gray;
          font-size: 40px;
          line-height: 23px;
        }
      }
  
      .close-button:hover {
        background-color: #eee;
      }

      .title {
        margin: 0;
        margin-bottom: 35px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
      }
    }
  }
}
