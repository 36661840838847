@import "../../../colors";

.Products {
  background-color: #fff;

  .loading-container {
    text-align: center;
    padding-top: 75px;
    height: 100vh;
    width: 100%;

    p {
      font-weight: 600;
      font-size: 16px;
      margin-top: 20px;
      color: #333;
    }
  }

  .productsContainer {
    max-width: 1280px;
    margin: auto;
    margin-bottom: 50px;
    padding-top: 60px;

    @media (max-width: 900px) {
      padding-top: 30px;
    }

    .topRowContainer {
      padding: 0 25px 10px 20px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .sortContainer {
        display: flex;
        align-items: center;

        label {
          margin-right: 5px;
          font-size: 15px;
        }

        select {
          padding-right: 35px;
        }
      }

      h2 {
        color: #333;
        font-size: 18px;
        line-height: 1.1;
        font-weight: 600;
        margin-right: 10px;
      }

      @media (max-width: 500px) {
        padding: 5px;

        h2 {
          margin-left: 10px;
        }
      }
    }

    .mobileFiltersButtonContainer {
      display: none;

      @media (max-width: 900px) {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 10px;
        margin-right: 25px;

        .mobileFiltersButton {
          border: 1px solid #bfbfbf;
          color: #404040;
          font-size: 16px;
          padding: 10px 12px 10px;
          background-color: #fff;
          font-weight: 400;

          img {
            margin-left: 25px;
          }
        }
      }

      @media (max-width: 500px) {
        margin-right: 5px;
      }
    }

    .productContentContainer {
      display: flex;
      padding: 0 20px;

      @media (max-width: 500px) {
        padding: 0;
      }

      .productFiltersContainer {
        width: 225px;
        margin-right: 20px;

        @media (max-width: 900px) {
          display: none;
        }

        .filterTypeContainer {
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 6px;
          margin: 5px 0 15px;
          padding: 10px;

          h3 {
            color: #333;
            margin: 0 0 12px;
            font-weight: 600;
          }

          .filterCheckboxRow {
            display: flex;
            padding: 3px;
            align-items: center;
            margin-bottom: 4px;

            input {
              margin-right: 10px;
            }

            label {
              margin-bottom: 0;
            }
          }
        }
      }
    
      .productResultsContainer {
        flex: 1;
    
        .productRow {
          flex: 1;
          display: flex;

          @media (max-width: 1240px) and (min-width: 900px) {
            flex-direction: column;
          }

          @media (max-width: 790px) {
            flex-direction: column;
          }

          .productRowInner {
            display: flex;
            flex: 1;
            margin: 0 -5px;
            margin: 0;

            .emptyProductContainer {
              flex: 1;
              margin: 5px;
              padding: 10px;
              border: 1px solid rgba(255, 255, 255, 0);
            }

            .productContainer {
              margin: 5px;
              position: relative;
              flex: 1;
              padding: 10px;
              text-align: center;
              vertical-align: top;
              cursor: pointer;
              border: 1px solid rgba(0, 0, 0, 0.2);
              border-radius: 6px;

              a {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                text-decoration: none;

                .productTopContainer {

                  img {
                    width: 100%;
                  }

                  .productTitle {
                    font-size: 16px;
                    line-height: 1.42857143;
                    color: #333;
                    font-weight: 600;
                    transition: all 200ms ease-in-out;
                    margin: 5px 0 10px;

                    &:hover {
                      color: $primaryColor;
                    }
                  }

                  .regularPrice {
                    margin: 0 0 25px;
                    font-weight: 600;
                    font-size: 14px;
                    display: inline-block;
                    color: #333;
                  }
                }

                .shopButtonContainer {
                  width: 100%;

                  .shopButtonMainContainer {
                    padding: 12px 24px;
                    font-size: 16px;
                    font-weight: 800;
                    letter-spacing: .12em;
                    line-height: 1;
                    width: 100%;

                    img {
                      height: 14px;
                      width: 14px;
                      margin-left: 10px;
                      filter: brightness(0) invert(1);
                    }

                    @media (max-width: 380px) {
                      font-size: 13px;
                      padding: 12px;

                      img {
                        height: 12px;
                        width: 12px;
                        margin-left: 7px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .resultsPerPageContainer {
          display: flex;
          flex-direction: row-reverse;
          padding-right: 5px;
          margin-top: 10px;

          .resultsPerPageInner {
            display: flex;
            align-items: center;

            p {
              margin: 0;
              font-weight: 600;
              font-size: 14px;
            }

            button {
              padding: 5px 10px;
              height: 30px;
              margin-left: 5px;
              font-size: 14px;
              font-weight: 500;
              background-color: $primaryColorLight;
              color: #2b2b2b;

              &.selected {
                background-color: $primaryColor;
                color: #fff;
              }
            }
          }
        }

        .paginationOuterContainer {
          .blogPaginationContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            margin-top: 10px;
  
            .blogPaginationInner {
              display: flex;
              flex-direction: row;
              align-items: center;
  
              p {
                width: 38px;
                text-align: center;
                line-height: 38px;
                cursor: pointer;
                color: #969696;
                height: 38px;
                margin-left: -1px;
                background-color: $primaryColorLight;
                border-radius: 4px;
                margin-left: 5px;
                
                &.selected {
                  background-color: $primaryColor;
                  color: #fff;
                }
              }
  
              .ellipsisText {
                letter-spacing: 3px;
                cursor: default;
                background-color: #fff;
  
                &:hover {
                  background-color: #fff;
                }
              }
  
              .blogPaginationArrows {
                font-size: 14px;
                font-weight: 500;
                background-color: #fff;
                color: #484848;
  
                &.first {
                  margin-right: 10px;
                }
  
                &.last {
                  margin-left: 10px;
                }
              }
  
              @media (max-width: 480px) {
                .ellipsisText {
                  display: none;
                }
  
                .additionalPageText {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .mobileFiltersContainer {
    position: fixed;
    z-index: 100;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: #fff;

    .mobileFiltersTopContainer {

      .mobileFiltersTitleContainer {
        border: 1px solid #efefef;
        padding-top: 5px;
        height: 41px;

        p {
          line-height: 30px;
          text-align: center;
          margin: 0;
          font-weight: 600;
          font-size: 18px;
        }
      }

      button {
        position: absolute;
        right: 0;
        top: 0;
        padding: 10px;
        line-height: 0;
        height: 40px;
        font-size: 35px;
        border-radius: 0;
        background-color: #fff;
        color: #333;
        border-left: 1px solid #efefef;
      }
    }

    .mobileFiltersContent {
      overflow: auto;
      height: 100%;
      padding-bottom: 120px;

      .resultCount {
        margin-left: 10px;
        font-weight: 600;
        color: #333;
      }

      .filterTypeContainer {
        border-bottom: 1px solid #efefef;
        margin: 0;
        padding: 10px;

        h3 {
          color: #333;
          margin: 0 0 12px;
          font-weight: 600;
        }

        .filterCheckboxRow {
          display: flex;
          padding: 3px;
          align-items: center;
          margin-bottom: 4px;

          input {
            margin-right: 10px;
          }

          label {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
