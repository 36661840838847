@import "../../../colors";

.PurchaseModal {
  position: relative;

  .loading-message-container {

    .LoadingMessage {
      position: absolute;

      .loading-inner {
        position: absolute;
      }
    }
  }

  .input-container {
    margin: 5px 0;

    label {
      color: #777777;
      margin-bottom: 0;
      font-size: 14px;
    }

    select {
      background-position: calc(100% - 21px) calc(1em - 2px), calc(100% - 16px) calc(1em - 2px), 100% 0;
      background-repeat: no-repeat;
      border-color: #bfbfbf;
      padding: 3px 10px;
    }

    input {
      width: 100%;
      border-radius: 4px;
      border-color: #bfbfbf;
      color: #404040;
      padding: 7px 10px;
      line-height: 1;
      margin-bottom: 0;

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #aaa;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #aaa;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: #aaa;
      }
    }
  }

  .top-section {
    margin-top: -20px;
    margin-bottom: 15px;

    .price-section {
      margin-top: 15px;
      padding: 7px 10px;
      overflow: hidden;

      .price-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 5px;

        p {
          margin: 0;
          color: rgb(88, 88, 88);
          font-size: 14px;

          .multiply {
            font-size: 16px;
            margin: 0 2px;
            line-height: 0;
          }
        }
      }

      .total-row {
        border-top: 1px solid #ddd;
        margin-top: 10px;
        padding-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        p {
          margin: 0;
          font-size: 16px;

          &.total-text {
            font-size: 24px;
          }
        }
      }

      .price-section-sub {
        background-color: #fafafa;
        border-top: 1px solid $borderLight;
        margin: 10px -10px -7px;
        padding: 3px 10px;

        p {
          margin: 0;
          font-size: 12px;
        }
      }
    }
  }

  .payment-details-container {

    .input-row {
      display: flex;
      justify-content: space-between;

      .half-width {
        width: 49%;
      }

      .full-width {
        width: 100%;
      }
    }

    @media (max-width: 500px) {
      .input-row {
        display: flex;
        flex-direction: column;

        .half-width {
          width: 100%;
        }
      }
    }

    .payment-section {
      margin-top: 15px;
    }
  }

  .error-container {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: .25rem;
    padding: .75rem 1.25rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 14px;
    display: flex;
    flex-direction: row;

    .error-icon-container {
      margin-right: 20px;
    }

    p {
      margin: 3px 0 10px;
      line-height: 16px;
    }
  }

  .terms-container {
    margin-top: 20px;
    display: flex;
    align-items: center;

    input[type="checkbox"] {
      margin-right: 10px;
      cursor: pointer;
    }

    label {
      color: #737373;
      font-size: 14px;
      margin: 0;
      line-height: 1.5;
      font-weight: 400;

      a {
        color: $primaryColor;
        cursor: pointer;
      }
    }
  }

  .bottom-buttons-container {
    margin-top: 20px;

    .place-order-button {
      width: 100%;
    }

    .cancel-text-container {
      text-align: center;

      .cancel-text {
        color: #057daf;
        font-size: 13px;
        cursor: pointer;
        text-decoration: underline;
        transition: all 0s;
        display: inline-block;
        text-align: center;
        margin-top: 20px;
      }
    }
  }
}
