.Modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;

  .modal-body {
    position: absolute;
    background-color: #fff;
    padding: 20px;
    padding-top: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 3px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    min-width: 500px;
    max-height: calc(100vh - 5px);
    overflow: auto;

    .close-button {
      height: 26px;
      width: 26px;
      border-radius: 26px;
      text-align: center;
      cursor: pointer;
      right: 5px;
      top: 5px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-around;

      i {
        margin-top: -4px;
        color: gray;
        font-size: 40px;
        line-height: 0;
      }
    }

    .close-button:hover {
      background-color: #eee;
    }

    .title {
      margin: 0;
      margin-bottom: 35px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;
    }

    .modal-content {
      margin-bottom: 35px;
      line-height: 22px;
    }

    .buttons-container {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 5px;
      }
    }
  }

  @media (max-width: 550px) {
    .modal-body {
      min-width: calc(100% - 30px);
      padding: 10px;
      padding-top: 20px;
    }
  }
}
