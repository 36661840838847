$primaryColor: #ff3312;
$primaryColorLight: #FFEFED;
$primaryColorMedium: #F7DBD7;
$secondaryColor: #e7ac00;
$successColor: #2BB673;
$alternateColor: #00afcc;
$borderLight: rgba(0, 0, 0, 0.1);
$borderDark: rgba(0, 0, 0, 0.2);
$transparent: rgba(0, 0, 0, 0);
$linkColor: #0e70be;
